@use '../../assets/styles/responsive.scss' as resp;

.holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 auto 0;
  //padding-top: var(--layout-padding-base);
  justify-content: center;

  @include resp.responsive(phone_tablet) {
    flex-direction: column;
  }

  .panel {
    width: 100%;
    margin: var(--layout-padding-base) var(--layout-padding-thin) 0;
    padding: var(--layout-padding-base);
    // border: 1px solid var(--layout-border-color);
    // border-radius: 18px;

    @include resp.responsive(tablet) {
      margin-left: auto;
      margin-right: auto;
    }
    @include resp.responsive(phone) {
      margin: 0;
      padding: var(--layout-padding-thin);
      border: unset;
    }

    &.hideOnPhone {
      @include resp.responsive(phone) {
        display: none;
        // background-color: red;
      }
    }

    &.panelLeft {
      position: relative;
      flex: 0 0 271px;
      box-sizing: border-box;
      background-color: var(--layout-background-color);
      border-radius: var(--layout-border-radius-base);

      @include resp.responsive(tablet) {
        width: 100%;
        max-width: 996px;
      }

      @include resp.responsive(phone) {
        padding-bottom: 0;
        border-radius: 0;

        // &::before {
        //   content: " ";
        //   position: absolute;
        //   display: block;
        //   bottom: 0;
        //   width: 100%;
        //   height: 1px;
        //   background: #d6d6d6;
        //   background: linear-gradient(90deg, white 0%, #d6d6d6 5%, #d6d6d6 95%, white 100%);
        // }
      }

      .profileCard,
      hr {
        @include resp.responsive(phone_tablet) {
          display: none;
        }
      }

      .profileCard {
        padding: var(--layout-padding-thin) var(--layout-padding-very-thin) var(--layout-padding-base);
      }

      hr {
        margin: 0 calc(var(--layout-padding-very-thin) * -1);
        border: unset;
        border-bottom: 1px solid var(--layout-border-color);
      }

      h2 {
        margin: 1em 0.2em;
        font-size: 1.5em;
      }
    }

    &.panelMain {
      flex: 1 1;
      max-width: 996px;
      background-color: var(--layout-background-color);
      border-radius: var(--layout-border-radius-base);
      box-sizing: border-box;

      @include resp.responsive(phone) {
        margin: 0;
        padding: var(--layout-padding-thin);
        border: unset;
        border-radius: 0;
      }

      h1 {
        margin-left: 0.2em;
      }

      .noCourses {
        flex: 1;
        min-width: 90%;
        padding: var(--layout-padding-base);
        margin: var(--layout-padding-base) auto 0;
        background-color: var(--layout-background-emphasize-color);
        border-radius: var(--layout-border-radius-base);
        text-align: center;

        p {
          font-size: 1.2em;
        }

        .btn {
          margin: var(--layout-padding-thick) var(--layout-padding-base) var(--layout-padding-base);
        }
      }
    }
  }
}

.allCoursesTitle {
  display: none;

  @include resp.responsive(phone) {
    display: block;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.streamsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.banner {
  background-color: var(--banner-background-color);
  box-sizing: border-box;
  padding: 16px;
  border-radius: 18px;
  max-height: 267px;
  display: flex;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
}

.text {
  flex-basis: fit-content;
  color: var(--font-color-banner-text);
}

.darkText {
  color: #5C5C5C;
}

.bannerTitle {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.bannerTextWrapper {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.bannerTexts {
  margin-top: 12px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.oneLine {
  display: flex;
  align-items: center;
  gap: 4px;
}

.bannerImage {
  overflow: hidden;
  padding: 0;
  border-radius: 8px;
  max-width: 418px;
  min-width: 300px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--banner-background-color);

  .bannerImg {
    width: 100%;
  }
}

.firstDayBg {
  background: url('../../assets/images/d1.jpg') center no-repeat;
  background-size: contain;
  border-radius: 12px;
}

.secondDayBg {
  background: url('../../assets/images/d2.jpg') center no-repeat;
  background-size: contain;
  border-radius: 12px;
  overflow: hidden;
}

.plug {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  background: #FF5252CC;
  height: 26px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.openButton {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 100px solid rgba(255, 255, 255, 0.25);
  border-bottom: 50px solid transparent;
  position: absolute;
  border-right: none;
  outline: none;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 52%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  //backdrop-filter: blur(5px);
  background: transparent;
  //background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}

.bannerButton {
  border: none;
  cursor: pointer;
  background: var(--button-base-background);
  width: 100%;
  height: 48px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: var(--font-color-base);
}

// 	width: 168px;
// overflow: hidden;
// text-overflow: ellipsis;
// white-space: nowrap;

.speakersWrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media screen and (max-width: 1023px) {
  .adaptiveHeading {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 900px) {
  .banner {
    gap: 12px;
  }
  .bannerTexts {
    justify-content: space-between;
    gap: 4px;
  }
  .bannerImage {
    flex: 0;
    height: 170px;
  }
}

.mobilePlug {
  color: white;
  background: rgba(255, 0, 0, 0.66);;
  height: 26px;
  border-radius: 8px;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: fit-content;
}

.dateWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
}

@media screen and (max-width: 720px) {
  .plug {
    bottom: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .banner {
    width: 100%;
    max-width: 418px;
    flex-direction: column;
    margin: auto;
  }
  .banner {
    height: fit-content;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    gap: 12px;
  }
  .bannerTexts {
    justify-content: space-between;
    gap: 4px;
    padding: 8px 0;
  }
  .bannerImage {
    height: 100%;
    border-radius: 18px 18px 0 0;
    // background-size: contain;
    // order: 1;
    // flex: 1;
    margin: auto;
  }
  .bannerTextWrapper {
    order: 2;
  }
  .bannerButton {
    //margin-top: 20px;
  }
  .bannerTextWrapper {
    align-items: start;
  }
}

@media (max-width: 1330px) {
  .bannerTitle {
    font-size: 14px;
  }
}

@media (max-width: 1200px) and (min-width: 721px) {
  .speakersWrapper {
    font-size: 12px;
  }
}

.description {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}