@use '../../assets/styles/responsive.scss' as resp;

.holder, .fullWidthHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 auto 0;
  justify-content: center;

  @include resp.responsive(phone_tablet) {
    flex-direction: column;
  }
  @include resp.responsive(phone) {
    background: var(--trainer-page-mobile-background);
  }

  .infoSection {
    margin-left: 8px;
    margin-bottom: 12px;
    padding: var(--layout-padding-base);
    border-radius: 24px;
    background: var(--stream-shedule-background);

    @include resp.responsive(phone_tablet) {
      margin: 30px 20px;
    }
    @include resp.responsive(phone) {
      background: var(--trainer-section-background);
    }

    .streamPreview {
      margin-bottom: 12px;
      border-radius: 12px;
      width: 100%;
      height: 100%;
    }
  }

  .panelSkeleton {
    width: 100%;

    @include resp.responsive(desktop) {
      display: none;
    }

    .personWrapper {
      padding: var(--layout-padding-base);
      background: var(--trainer-section-background);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      @include resp.responsive(phone) {
        margin: var(--layout-padding-base);
      }

      @include resp.responsive(tablet) {
        background: var(--stream-shedule-background);
        margin: var(--layout-padding-base);
      }

      @include resp.responsive(desktop) {
        background: var(--stream-shedule-background);
      }

      .avatarSkeleton {
        margin: 0 auto;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .descriptionSkeleton {
        height: 3rem;
      }
    }


  }

  .panel {
    width: 100%;
    margin: 0 var(--layout-padding-thin) 0;
    //padding: var(--layout-padding-base);

    @include resp.responsive(tablet) {
      margin-left: auto;
      margin-right: auto;
    }
    @include resp.responsive(phone) {
      margin: 0;
      margin-top: 20px;
      border: unset;
    }

    &.hideOnPhone {
      @include resp.responsive(phone) {
        display: none;
      }
    }

    &.panelLeft {
      height: fit-content;
      position: relative;
      flex: 0 0 310px;
      gap: 16px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      //background-color: var(--layout-background-color);
      //border-radius: var(--layout-border-radius-base);

      @include resp.responsive(tablet) {
        width: unset;
        max-width: 996px;
        margin: 0 20px;
        margin-bottom: 30px;
      }

      @include resp.responsive(phone) {
        flex: unset;
        margin: 0;
        margin-bottom: 30px;
        overflow-x: auto;
        min-height: 380px;
        background: transparent;
      }
    }

    &.panelMain {
      flex: 1 1;
      max-width: 996px;
      background-color: var(--layout-background-color);
      border-radius: var(--layout-border-radius-base);
      box-sizing: border-box;

      @include resp.responsive(phone) {
        margin: 0;
        padding: var(--layout-padding-thin);
        border: unset;
        border-radius: 0;
      }
    }
  }

  .fullWidthPanel {
    width: 100%;
  }

  .section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    background: var(--trainer-section-background);
    border-radius: 18px;
    padding: 16px;

    @include resp.responsive(tablet) {
      min-width: 240px;
      width: 49%;
      max-width: 49%;
    }

    @include resp.responsive(phone) {
      min-width: 100%;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }

  .avatarImage {
    border-radius: 50%;
    overflow: hidden;
  }

  .description {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 13px;
    line-height: 1.333;

    .descriptionText {
      text-align: start;
    }
  }

  .name {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

  .line {
    width: 100%;
    color: #d6d6d6;
    background: #d6d6d6;
    outline: none;
    height: 1px;
    margin: 10px 0;
  }

  .time {
    font-weight: bold;
    //font-weight: 500;
    font-size: 14px;
    //color: #5c5c5c;
  }

  .bold {
    font-weight: 700;
  }

  .title {
    width: 100%;
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    @include resp.responsive(phone_tablet) {
      font-size: 20px;
    }
  }

  .pageTitle {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .secondTitle {
    font-weight: 600;
    font-size: 24px;
  }

  .titleWithSpacing {
    display: flex;
    margin-bottom: 20px;
  }

  .thirdTitle {
    font-weight: 700;
    font-size: 16px;
  }

  .courseDescription {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include resp.responsive(phone) {
      margin-top: 16px;
      gap: 8px;
    }
  }

  .listDescription {
    padding-left: 30px;
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include resp.responsive(phone) {
      margin-top: 16px;
    }
  }

  .highlightedText {
    color: var(--scrollbar-thumb-active-color);
    text-transform: uppercase;
    fill: var(--scrollbar-thumb-active-color);

  }

  .lineWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .schedule {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;

  }

  .timings {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .video {
    margin-bottom: 20px;
    border-radius: 24px;
    border: none;
  }

  @media screen and (max-width: 576px) {
    .video {
      height: 310px;
    }
    .schedule {
      flex-direction: column;
      align-items: start;
      font-size: 14px;
    }
    .descriptionText {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 576px) {
    .video {
      height: 430px;
    }
  }
  @media screen and (min-width: 768px) {
    .video {
      height: 570px;
    }
  }
  @media screen and (min-width: 992px) {
    .video {
      height: 510px;
    }
  }
  @media screen and (min-width: 1200px) {
    .video {
      height: 635px;
    }
  }
  @media screen and (min-width: 1400px) {
    .video {
      height: 660px;
    }
  }

  .trainersHolder {
    padding: var(--layout-padding-base);
    width: unset;

    background-color: var(--layout-background-color);
    border-radius: var(--layout-border-radius-base);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    @include resp.responsive(phone_tablet) {
      justify-content: stretch;
      //flex-direction: column;
      overflow-x: auto;
      align-items: stretch;
    }
  }

  .supportSection {
    @include resp.responsive(phone_tablet) {
      flex-direction: row;
      flex-wrap: wrap;
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }

  .doubleDescription {
    display: flex;
    width: 90%;
    justify-content: space-between;
  }

  .text {
    color: var(--font-color-base);
    text-decoration: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@include resp.responsive(phone_tablet) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}

.fullWidthHolder {
  display: block;
}

.videoCoverWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .videoCover {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 24px;
  }

  .play {
    position: absolute;
    padding: 8px 16px;
    background-color: #FF5252;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    user-select: none;

    .playBtnTime {
      font-weight: 600;
    }
  }

  .playBtn {
    gap: 12px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.streamSkeleton {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 20px;
  border-radius: 24px;
}

.streamDescriptionLoader {
  width: 100%;
  height: 200px;
  border-radius: 24px;
}