@use '../../assets/styles/responsive.scss' as resp;
@use '../../assets/styles/mixins.scss' as mixin;

.holder {
  display: grid;
  grid-template-columns: auto 320px;
  grid-template-rows: min-content 1fr;
  margin: 0 auto var(--layout-padding-thick);
  padding: 0 var(--layout-padding-thin);

  @include resp.responsive(phone_tablet) {
    grid-template-columns: 100%;
  }

  @include resp.responsive(phone) {
    padding: 0;
  }

  .videoWrapper {
    height: fit-content;
    position: relative;
    padding: var(--layout-padding-base);
    padding-bottom: 0;
    background-color: var(--layout-background-color);
    border-top-left-radius: var(--layout-border-radius-base);
    @include resp.responsive(tablet)  {
      border-top-right-radius: var(--layout-border-radius-base);
    }

    @include resp.responsive(phone) {
      margin: 0 ;
      border-radius: 0;
      padding: var(--layout-padding-thin) var(--layout-padding-thin) 0 var(--layout-padding-thin);
    }
  }

  .description {
    padding: var(--layout-padding-base);
    background-color: var(--layout-background-color);
    border-bottom-left-radius: var(--layout-border-radius-base);

    @include resp.responsive(phone_tablet) {
      order: 2;
      border-radius: 0;
    }

    @include resp.responsive(phone) {
      order: 2;
      padding: var(--layout-padding-thin);

      h1 {
        padding: 0 var(--layout-padding-base);
      }
    }

    .title {
      font-size: 1.3em;
      font-weight: var(--font-weight-bold);
    }

    .content {
      padding: var(--layout-padding-base);
      border-radius: var(--layout-border-radius-base);
      background-color: var(--layout-background-emphasize-color);
      margin: var(--layout-padding-base) 0;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: var(--layout-background-emphasize-color);
          opacity: 0.8;
        }
      }

      .showMore {
        margin: var(--layout-padding-thin) 0 0;
        font-weight: bold;
      }
    }
  }

  .heightFixBlock {
    display: flex;
    flex-direction: column;
    padding-bottom: 2px;
    overflow: hidden;
    border-top-right-radius: var(--layout-border-radius-base);
    background-color: var(--layout-background-color);

    @include resp.responsive(phone_tablet) {
      order: 3;
      border-radius: 0;
    }

    h3 {
      font-size: 1.2em;
      padding: 1em;
      margin: 0 0 1px 0;
      background-color: var(--layout-background-emphasize-color);

      @include mixin.smooth-bottom-border;
    }

    .scrollHolder {
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      overflow: auto;

      @include mixin.custom-scroll;
      @include mixin.custom-scroll-padded;
    }

    .episodeListBlock {
      position: absolute;
      padding: 0;
      width: 100%;
      border: unset;
    }

    @include resp.responsive(phone_tablet) {
      order: 3;

      .episodeListBlock {
        position: unset;
      }
    }
  }

  .extendedVideos {
    order: 2;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .videoListHolder {

    @include resp.responsive(phone_tablet) {
      order: 4;
    }

    &.noEpisodes {
      .videoListBlock {
        border-top-right-radius: var(--layout-border-radius-base);

        @include resp.responsive(phone_tablet) {
          border-radius: 0;
        }
      }

      @include resp.responsive(phone_tablet) {
        grid-row-start: unset;
        grid-row-end: unset;
        grid-column-start: unset;
      }
    }

    .videoListBlock {
      height: 100%;
      padding: 0;
      border-bottom-right-radius: var(--layout-border-radius-base);
      background-color: var(--layout-background-color);
      overflow: hidden;



      h3 {
        font-size: 1.2em;
        padding: 1em;
        margin: 0;
        background-color: var(--layout-background-emphasize-color);

        @include mixin.smooth-bottom-border;
      }
    }
  }
}
