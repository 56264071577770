.button {
  font-family: var(--font-family-base);
  font-size: 0.94em;
  font-weight: 700;
  text-align: center;
  color: var(--button-base-text-color-base);
  border-radius: 8px;
  line-height: 2.66em;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.default {
  background-color: var(--button-base-backgound-color);

  &:hover {
    background-color: var(--button-base-backgound-color-hover);
  }
}

.muted {
  background-color: var(--font-color-muted);
  color: var(--button-base-text-color-base);
  cursor: not-allowed;

  &:hover {
    background-color: var(--font-color-muted);
    color: var(--button-base-text-color-hover);
  }
}

.colorful {
  background-color: var(--button-colorful-backgound-color);
  color: var(--button-colorful-text-color-base);

  &:hover {
    background-color: var(--button-colorful-backgound-dark);
    color: var(--button-colorful-text-color-hover);
  }
}