@use '../../assets/styles/mixins.scss' as mixin;

.videoList {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .noVideo {
    padding: var(--layout-padding-base);
  }

  p {
    margin: 0.2em 0.3em;
  }
}
.buttons {
  margin-top: var(--layout-padding-base);
}
.loader {
  display: flex;
justify-content: center;
  align-items: center;
  padding: 1rem;
  @include mixin.loader;
}
