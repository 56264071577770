@use '../../../assets/styles/responsive.scss' as resp;

.tile {
  // min-width: 286px;
  max-width: 500px;
  width: 100%;
  border: 1px solid var(--layout-background-color);
  border-radius: var(--layout-border-radius-base);
  color: var(--font-color-base);
  text-decoration: unset;
  box-sizing: border-box;
  overflow-wrap: anywhere; // Comp: 95.23%. The long, glued text should be broken up
  background-color: var(--page-background-color);
  overflow: hidden;

  @include resp.responsive(phone) {
    max-width: 418px;
    padding: 0 0 var(--layout-padding-base);
    margin: 0 0 var(--layout-padding-base);
    border: unset;
    border-bottom: 1px solid var(--layout-border-color);
  }

  .image {
    // border-radius: var(--layout-border-radius-base);
    aspect-ratio: 16 / 9;
    overflow: hidden;

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &:hover {
    color: var(--font-color-base);
    background-color: var(--hover-color);

    @include resp.responsive(desktop_tablet) {
      // border-color: var(--layout-border-color-dark);
      // box-shadow: 0 0 var(--layout-padding-thin) 0 rgba(0,0,0,0.2);
    }
  }

  .info {
    padding: var(--layout-padding-base);

    .title {
      padding: 0;
      font-size: 1.3em;
      line-height: 1.2em;
      font-weight: 600;
    }

    .description {
      margin: 10px 0 0;
      font-size: 0.95em;
      line-height: 1.3em;
      color: var(--font-color-muted);
    }
  }
}
