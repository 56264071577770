.breadcrumbs {
  display: flex;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 var(--layout-padding-base);
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .item,
  a.item {
    color: var(--font-color-muted);
    text-decoration: none;
  }
  a.item:hover {
    color: var(--font-color-base);
    text-decoration: underline;
  }

  .block {
    display: block;

    .item {
      line-height: 24px;

      .label {
        line-height: 24px;
      }
      .delimeter {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../../assets/icons/chevron-right.png');
      }
    }
  }
}