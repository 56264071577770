@use '../../assets/styles/responsive.scss' as resp;

.grid {
  display: grid;
  margin: 0 auto;
  padding: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: var(--layout-border-radius-base);
  justify-items: center;
}

.mobileContainer {
  padding-bottom: 20px;
  display: flex;
  gap: 20px;
  overflow-x: scroll;
    > :first-child {
      background: #D2DBE2;
    }
    > :nth-child(2) {
      background: #DFD1E7;
    }
    > :nth-child(3) {
      background: #EFEFEF;
    }
}

.item {
  background-color: var(--page-background-color);
}
.lightItem {
  div{
    color: black;
  }
}

.mobileItem {
  flex-basis: 262px;
  //background-color: var(--page-background-color);
  border-radius: 18px;
  border: 1px solid #8a8eae;
  padding: 0 15px;
  min-width: 230px;
  max-width: 262px;
  min-height: 360px;
}
