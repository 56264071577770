.avatar {
	min-width: 60px;
	min-height: 60px;
	max-height: 60px;
	font-size: 30px;
	color: #fff;
	user-select: none;
	border: 1px solid var(--border-primary);
	border-radius: 50%;

	img {
		max-width: 60px;
		max-height: 60px;
		border-radius: 50%;
	}
}

.small {
	min-width: 40px;
	min-height: 40px;
	font-size: 20px;
}

.extraSmall {
	min-width: 32px;
	min-height: 32px;
	font-size: 14px;
}
