@import './themes.scss';

:root {
  // General
  --page-background-color: #fcfcfe;
  --banner-background-color: #ebc9f2;

  // Fonts
  --font-family-base: 'Raleway', Arial, Helvetica, sans-serif;
  --font-family-numeric: Arial, Helvetica, sans-serif;
  --font-size-base: 16px;
  --font-size-input: 1.5em;
  --font-weight-normal: 400;
  --font-weight-bold: 600;
  --font-weight-bolder: 700;

  // Fonts colors
  --font-color-base: #0b0c10;
  --font-color-muted: #6e6e73;
  --font-color-error: #e50000;
  --font-color-link: #8d52ec; // #477BFF;
  --font-color-link-hover: #4f00cf; //#275ce4;
  --font-color-white: #fff;

  --font-color-banner-text: black;

  // Layout
  --layout-max-width: 1440px;
  --layout-min-width: 320px;
  --layout-header-height: 70px;
  --layout-padding-thick: 32px;
  --layout-padding-base: 20px; // 16px;
  --layout-padding-reduced: 16px; // 12px;
  --layout-padding-thin: 12px; // 8px;
  --layout-padding-very-thin: 6px; // 4px;

  --layout-background-color: #ececf8;
  --layout-background-emphasize-color: #d5d5ef;
  --layout-background-modal-color: white;
  --layout-shadows-color-rgb: 0, 0, 0; // RGB color for construct rgba()

  --layout-border-radius-base: 24px; //12px;
  --layout-border-radius-reduced: 18px; //8px;
  --layout-border-radius-small: 12px; //6px;
  --layout-border-radius-tiny: 8px; //4px;
  --layout-border-radius-subtle: 4px; //2px;

  --layout-border-color: #c2a9eb;
  --layout-border-color-light: #d3c0f1;
  --layout-border-color-dark: #8d52ec; //#c1c1c1;

  --layout-color-steel: #e0eaf1;

  --layout-secondary-color-base: #8d52ec;
  --layout-secondary-color-light: #d5d5ef;
  --layout-secondary-color-dark: #7428ec;

  // Scroll
  --scrollbar-track-base-color: #f6e0f7;
  --scrollbar-track-active-color: #f6cef7;
  --scrollbar-thumb-base-color: #eaa7eb;
  --scrollbar-thumb-active-color: #e564e8;

  // Player
  --video-control-background-color: white;
  --video-timeline-background-color: #e7e7e7;
  --video-timeline-loaded-color: #d6d6d6;
  --video-timeline-played-color: var(--layout-secondary-color-dark);
  --video-timeline-target-color: var(--layout-secondary-color-dark);
  --video-play-btn-background-color: var(--video-control-background-color);
  --video-play-btn-border-color: var(--video-timeline-background-color);
  --video-play-btn-hover-background-color: var(
    --video-play-btn-background-color
  );
  --video-play-btn-hover-border-color: #c0c0c0;

  // Other
  --form-elements-margin: 5px;
  --menu-underline-size: 2px;
  --loader-color: #766df4;

  --trainer-section-background: white;
  --stream-shedule-background: #ececf8;
  --trainer-page-mobile-background: #ececf8;

  // Buttons
  --button-base-backgound-color: #0b0c10;
  --button-base-backgound-color-hover: #333;
  --button-base-text-color-base: white;
  --button-base-text-color-hover: white;

  --button-base-background: #ffffff;

  --button-colorful-backgound-color: var(--layout-secondary-color-base);
  --button-colorful-backgound-dark: var(--layout-secondary-color-dark);
  --button-colorful-text-color-base: white;
  --button-colorful-text-color-hover: white;

  // Skeleton
  --skeleton-base-color: #ebebeb;
  --skeleton-highlight-color: #f5f5f5;
  --skeleton-image-color: #d0d0e1;

  // Hover color
  --hover-color: #00000010;

  --stroke: #40494a;
}
