@use '../../../assets/styles/mixins.scss' as mixin;

.videoCard {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--layout-padding-thin);
  cursor: pointer;
  color: var(--font-color-base);
  text-decoration: none;

  &:not(:last-child) {
    @include mixin.smooth-bottom-border;
  }

  &:hover {
    background-color: var(--layout-secondary-color-light);
    color: var(--font-color-base);

    &::after {
      display: none;
    }
  }

  .videoCover {
    width: 100%;
    box-shadow: 0 0 1px black;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .videoText {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
    padding: 8px;
    overflow: hidden;

    .videoHeading {
      width: 100%;
      display: -webkit-box;
      margin: 0;
      font-size: 0.9em;
      font-weight: var(--font-weight-bold);
      line-height: 1.1em;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    // .videoDescription {
    //   color: var(--font-color-muted);
    //   font-size: 0.9em;
    //   line-height: 1.1em;
    //   overflow-wrap: anywhere;
    // }
  }
  .videoDuration {
    position: absolute;
    right: calc(8px + var(--layout-padding-thin));
    top: calc(8px + var(--layout-padding-thin));
    display: flex;
    flex-direction: row;
    color: white;
    background-color: var(--layout-secondary-color-base);
    align-items: center;
    font-size: 0.65em;
    border-radius: 999px;
    padding: 6px 8px;

    span {
      padding: 1px 5px;
      margin: 0;
    }
    .approxDuration {
      display: block;
    }
    .exactDuration {
      display: none;
      padding-right: 5px;
    }
  }

  &:hover,
  &:focus {
    .approxDuration {
      display: none;
    }
    .exactDuration {
      display: block;
    }
  }
}

.activeCard {
  background-color: var(--layout-secondary-color-light);
  border-top: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
}

.disabled {
  opacity: 0.5;
  cursor: default;
}