@use '../../assets/styles/responsive.scss' as resp;

.grid {
  width: 100%;
  display: grid;
  margin: 0 auto;
  padding: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--layout-padding-base);
  justify-items: center;

  @include resp.responsive(phone) {
    position: relative;
    margin-top: var(--layout-padding-base);
    grid-template-columns: 1fr;
  }

  .observeTrigger {
    height: 0; // this element must not obscure interaction with underlying elements
    width: 100%;
    margin-top: -300px; // move observer to the top
  }
}
