$max: 1440px; // Max design size
$desktop: 1024px; // PC screen size.
$tablet: 720px; // Tablet screen size.
$phone: 320px; // Phone screen size.

@mixin responsive($media) {
  @if ($media == landscape){
    @media only screen and (orientation: landscape) {
      @content;
    }
  }
  @if ($media == phone) {
    @media only screen and (max-width: #{$tablet - 1}) {
      @content;
    }
  }
  @else if ($media == tablet) {
    @media only screen and (min-width: $tablet) and (max-width: #{$desktop - 1}) {
      @content;
    }
  }
  @else if ($media == desktop) {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }
  @else if ($media == desktop_tablet) {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  }
  @else  if ($media == phone_tablet) {
    @media only screen and (max-width: #{$desktop - 1}) {
      @content;
    }
  }
  @else if ($media == max) {
    @media only screen and (min-width: $max) {
      @content;
    }
  }
}