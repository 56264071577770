.videoLine {
  position: relative;
  width: 100%;
  height: 18px;
  -webkit-user-select: none;
  user-select: none;

  .videoLineRounder {
    display: block;
    position: relative;
    background-color: var(--video-timeline-background-color);
    border-radius: 9px;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;

    .videoLineOverlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-sizing: border-box
    }

    .loadedBar {
      height: 18px;
      width: 18px;
      background-color: var(--video-timeline-loaded-color);
    }

    .playedBar,
    .targetBar {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0;
    }
    .playedBar {
      background-color: var(--video-timeline-played-color);
    }

    .targetBar {
      opacity: 0;
      transition: opacity 0.2s ease 0s;
      background-color: var(--video-timeline-target-color);
    }
  }

  .targetTime {
    position: absolute;
    display: none;
    bottom: 26px;
    transform: translate(-50%, 0);
    padding: 3px 10px;
    background-color: var(--font-color-base);
    color: var(--layout-background-color);
    font-size: 0.75em;
    font-family: var(--font-family-numeric);
    line-height: 1em;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0.5em;

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: var(--font-color-base);
      position: absolute;
      bottom: -4px;
      left: 50%;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
      transform: translate(-50%, 0) rotate(45deg);
      border-radius: 10px 0 0 0;
    }
  }

  &:hover {
    .targetBar {
      opacity: 0.5;
    }
    .targetTime {
      display: block;
    }
  }
}

// .videoLine {
//     width: 100%;
//     height: 32px;
//     display: flex;
//     align-items: center;

//     & > input {
//       -webkit-appearance: none;
//       -moz-appearance: none;
//       appearance: none;
//       width: 100%;
//     }
//     & > input::-webkit-slider-runnable-track {
//       height: 18px;
//       background-color: var(--layout-border-color);
//       border-radius: 9px;
//     }
//     & > input::-webkit-slider-thumb {
//       -webkit-appearance: none;
//       background: #eaa7eb;
//       height: 18px;
//       width: 18px;
//       border-radius: 50%;
//     }
//   }