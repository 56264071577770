@use '../../assets/styles/responsive.scss' as resp;

.defaultLayout {
  display: block;
  min-height: 100%;
  padding-bottom: var(--layout-padding-thick);

  .pageWrapper {
    margin: 0 auto;
    max-width: var(--layout-max-width);

    @include resp.responsive(phone) {
      background-color: var(--layout-background-color);
    }
  }

  .breadcrumbs {
    padding: var(--layout-padding-base);
    background-color: var(--page-background-color);
  }

  //   @include resp.responsive(phone) {
  //     padding-bottom: 0;
  //   }
}
