@use '../../assets/styles/responsive.scss' as resp;

.holder {

  .body {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    height: 100vh;
    margin: 0 auto;
    box-sizing: border-box;

    .spacer {
      flex: 1 1;
    }

    .content {
      flex: 0 0;
      padding: var(--layout-padding-thick);
      border: 1px solid var(--layout-border-color);
      border-radius: var(--layout-border-radius-base);

      @include resp.responsive(phone_tablet) {
        border-color: transparent;
      }

      .code {
        font-size: 16em;
        font-weight: var(--font-weight-bold);
        text-align: center;
        font-variant-numeric: normal;

        @include resp.responsive(phone_tablet) {
          font-size: 8em;
        }

        span:nth-child(2) {
          color: var(--layout-secondary-color-base);
        }
      }

      .title {
        margin: 1em auto;
        font-size: 1.75em;
        font-weight: var(--font-weight-bold);
        text-align: center;

        @include resp.responsive(phone_tablet) {
          font-size: 1.5em;
        }
      }

      .description {
        margin: 1em auto;
        font-size: 1.5em;
        line-height: 1.15em;
        text-align: center;

        @include resp.responsive(phone_tablet) {
          font-size: 1.2em;
        }
      }

      .button {
        width: 420px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  &.headerFix {
    margin-top: -80px;

    .spacer:nth-of-type(1) {
      min-height: 80px;
    }
  }
}