.wrapper {
  flex: 1;
  flex-basis: 30px;
  display: flex;
  padding: 9px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #EFEFEF;
  gap: 10px;
  background-color: var(--page-background-color);
  color: var(--button-base-text-color-base);
}

.resetButton {
  border: none;
  cursor: pointer;
  display: flex;
  width: 36px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: transparent;
}

.search {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.searchInput {
  margin-left: 10px;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 20px;
  background-color: var(--page-background-color);
  color: var(--font-color-base);
}

//.searchInput:focus {
//  border: 1px solid red;
//}

.searchedResults {
  font-size: 1.25rem;
  word-wrap: break-word;
}

