@use '../../assets/styles/responsive.scss' as resp;
@use '../../assets/styles/mixins.scss' as mixin;

.holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  // overflow-y: auto;
  border: 1px solid var(--layout-border-color-light);
  border-radius: var(--layout-border-radius-base);
  background-color: var(--layout-background-color);
  box-sizing: border-box;

  @include resp.responsive(phone_tablet) {
    flex-direction: column;
    border: 0;
  }
}
.courseDescription {
  padding: var(--layout-padding-base);
  overflow-wrap: anywhere;
}
.courseContent {
  flex: 0 0 320px;
  border-left: 1px solid var(--layout-border-color-light);
  overflow-wrap: anywhere;
  box-sizing: border-box;

  .videosHeading {
    border-top-right-radius: var(--layout-border-radius-base);
  }

  @include resp.responsive(phone_tablet) {
    flex: 0 0;
    border: 0;
  }

  h2 {
    margin: 0;
    padding: 0.6em;
    font-size: 1.5em;
    font-weight: var(--font-weight-normal);
    background-color: var(--layout-background-emphasize-color);

    @include mixin.smooth-bottom-border;
  }

  .videoList {
    @include mixin.smooth-bottom-border;

    &::after {
      bottom: 0;
    }
  }
}
.courseDescription {
  flex: 1 1;
  color: var(--font-color-muted);

  .coverImage {
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      border-radius: var(--layout-border-radius-base);
      background: var(--layout-color-steel);
      overflow: hidden;
    }
  }
  .title {
  
    line-height: 24px;
    margin: 0.5em 0;

    .watchBtn {
      float: right;
      margin-left: 15px;
      font-size: 14px;
      line-height: 1.2;
      font-weight: normal;
      width: fit-content;
      padding: 8px 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
  p {
    margin: 0.7em 0;
    line-height: 1.3em;
  }
  h1,
  h2,
  h3 {
    margin: 1em 0 0.5em;
    color: var(--font-color-base);
  }
  h2 {
    font-size: 1.5em;
  }
  .buttonHolder {
    margin: var(--layout-padding-thick) var(--layout-padding-base)
      var(--layout-padding-base);
  }

  .downloadButton {
    width: fit-content;
    padding: 0 2.5rem;
  }
}

.autoSubBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  font-size: 14px;
  line-height: 1.2;
  gap: 8px;
  padding: 8px 16px;

  .ciIcon {
    height: 20px;
    width: 20px;
  }
}

.playIcon {
  width: 16px;
  height: 16px;
}

.courseTitle {
  font-size: 1.3rem;
  font-weight: bold;
}