@use '../../assets/styles/mixins.scss' as mixin;

.videoContainer {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  aspect-ratio: 16 / 9;

  .videoWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: var(--layout-border-radius-base);
    overflow: hidden;

    .spacer {
      flex: 1 1;
    }

    .videoHolder {
      flex: 0 0 auto;
      position: relative;
      max-width: 100%;
      width: 100%;
      height: 100%;

      .videoCover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .loaderHolder{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--skeleton-image-color);
        z-index: 2;

        .loader{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include mixin.loader;
        }
      }
    }
  }

  video {
    display: block;
  }

  .controls {
    opacity: 1;
    transition: opacity 0.1s;
    z-index: 3;

    &.hidden {
      opacity: 0;
      transition: opacity 0.5s;
      cursor: none;
    }
  }
}

.disablingVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0,0,0, 0.5);
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disablingVideoText {
  color: var(--font-color-white);
  text-align: center;
  font-size: var(--font-size-base);
  font-weight: bolder;
}