@use '../../assets/styles/responsive.scss' as resp;

.holder {
  display: block;
  padding-top: var(--layout-padding-thick);
  padding-bottom: var(--layout-padding-thick);
  max-width: 800px;
  margin: 0 auto;

  .contentWrapper{
    margin: 0 var(--layout-padding-thin);

    h1, h2 {
      text-align: center;
    }
    h1 {
      @include resp.responsive(phone) {
        font-size: 1.6em;
      }
    }
    h2 {
      padding-top: var(--layout-padding-base);
      font-size: 1.4em;
    }
    .emphasized {
      font-weight: var(--font-weight-bold);
    }
    .leftAligned {
      text-align: right;
    }
    .logo {
      text-align: center;
      margin: 0 0 var(--layout-padding-thick) 0;

      img {
        margin: 0 auto;
      }
    }
  }
}