@use '../../assets/styles/responsive.scss' as resp;

.fixHtml{
  figure { // style hack to fix fixed width in content data
    width: auto !important;
    margin: 0;
  }
  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}

table {
  width: 100%;
  overflow-wrap: normal;
  @include resp.responsive(phone_tablet) {
    tr {
      display: flex;
      flex-direction: column;
      td {
        div:has(> figure) {
          display: flex;
          justify-content: center;
        }
        img {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
