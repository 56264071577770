@use '../../assets/styles/responsive.scss' as resp;
@use '../../assets/styles/mixins.scss' as mixin;
@use '../../assets/styles/themes.scss' as themes;

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--layout-background-color);
  z-index: 999;

  // &::after { // Under-header gradient line
  //     content: ' ';
  //     position: absolute;
  //     display: block;
  //     bottom: 0;
  //     width: 100%;
  //     height: 1px;
  //     background: var(--layout-border-color);
  //     z-index: -1;
  // }

  // @include resp.responsive(max) {
  //   @include mixin.smooth-bottom-border;
  // }

  &-open::after {// hide under-header line when opened on mobile
      @include resp.responsive(phone_tablet) {
        display: none;
      }
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--font-family-base);
    max-width: 1260px;
    height: var(--layout-header-height);
    padding: 0 var(--layout-padding-base);
    margin: 0 auto;

    .logoWrapper {
      padding-left: 16px;
      flex: 0 0;
      margin-right: 1em;
      z-index: 999;

      @include resp.responsive(phone_tablet) {
        padding-left: 0;
      }

      .logo {
        height: 50px;
      }
    }

    .spacer {
      flex: 1 1;
    }

    .menuHolder {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      align-items: center;

      .menuItem {
        @include resp.responsive(desktop) {
          font-size: 1.2em;
        }
      }
    }

    .loginBlock {
      display: none;
    }

    .profile {
      position: relative;
      padding: 10px 20px;
      cursor: pointer;

      .profileIcon {
        display: block;
        width: 36px;
        height: 36px;
        background: url('./../../assets/icons/themes/light/profile.png');

        @include themes.with(themes.$themeDark) {
          background: url('./../../assets/icons/themes/dark/profile.png');
        }

        @include themes.with(themes.$themeLight) {
          background: url('./../../assets/icons/themes/light/profile.png');
        }
      }

      .headerAvatar > img {
        width: 40px;
        height: 40px;
      }

      .dropdown {
        position: absolute;
        top: 100%;
        right: 20px;
        overflow-wrap: anywhere;
        z-index: 999;

        .dropdownItem {
          a, .link {
            display: block;
            font-size: 14px;
            padding: var(--layout-padding-very-thin) 0;
            color: var(--font-color-base);
            text-decoration: unset;
          }
          a:hover, .link:hover {
            opacity: 0.9;
            text-decoration: none;
          }
        }
      }
    }

    .hamburger {
      border: 10px solid transparent;
      // border: 1px solid red;
      cursor: pointer;

      img {
        width: 33px;
        height: 33px;
      }
    }

    .desktopOnly {
      @include resp.responsive(phone_tablet) {
        display: none;
      }
    }
    .mobileTabletOnly {
      @include resp.responsive(desktop) {
        display: none;
      }
    }

    @include resp.responsive(phone_tablet) {
      &-open {
        &::before{
          content: ' ';
          position: fixed;
          top: var(--layout-header-height);
          right: 0;
          bottom: 0;
          left: 0;
          background-color: var(--page-background-color);
        }

        &::after {
          content: '';
        }

        .menu {
          position: fixed;
          display: block;
          top: 0;
          right: auto;
          left: 50%;
          bottom: auto;
          width: 100%;
          height: 100%;
          max-width: 600px;
          min-width: var(--layout-min-width);
          padding: calc(var(--layout-header-height) + var(--layout-padding-base)) var(--layout-padding-thin) var(--layout-padding-base) var(--layout-padding-thin);
          transform: translate(-50%, 0);
          box-sizing: border-box;

          @include resp.responsive(landscape) {
            max-width: unset;
            padding: calc(var(--layout-header-height) + var(--layout-padding-base)) var(--layout-padding-thin) var(--layout-padding-thin) var(--layout-padding-thin);
          }

          .menuHolder {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background: var(--layout-background-color);
            border: 1px solid var(--layout-border-color);
            border-radius: var(--layout-border-radius-base);
            overflow: auto;
            box-sizing: border-box;

            @include resp.responsive(landscape) {
              flex-direction: row;
              align-items: center;
            }

            .profileBlock {
              flex: 0 0;
              width: 100%;
              padding: var(--layout-padding-base);
              // margin: var(--layout-padding-thin);
              border-bottom: 1px solid var(--layout-border-color);
              text-align: center;
              box-sizing: border-box;

              @include resp.responsive(landscape) {
                flex: 1 1;
                border-bottom: unset;
              }

              a {
                text-decoration: none;
                color: var(--font-color-base);
              }
              img {
                margin: 0 auto;
              }
              .name {
                padding-top: 0.5em;
                font-size: 1.5em;
                overflow-wrap: anywhere;
              }
            }

            .menuBlock {
              flex: 1 1;
              display: flex;
              flex-direction: column;

              .menuBlockHolder {
                padding-top: 1em;
                padding-bottom: 0.5em;
                flex-direction: column;
                height: unset;
                border: unset;

                .menuItem {
                  padding: 0.4em;

                  span {
                    padding-bottom: 0;
                  }
                }
              }

              .spacer {
                flex: 1 1;
              }

              .notificationsBlock {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 48px;
                gap: 4px;


                .notificationsButton {
                  color: var(--font-color-base);
                  outline: none;
                  background: none;
                  border: none;
                  font-size: 1.2em;
                  font-weight: 300;
                  cursor: pointer;
                }
              }

              .additionalMenuBlock {
                flex: 0 0;
                display: flex;
                flex-direction: column;

                .menuItem{
                  padding: 0.4em;
                }
              }

              .redselected {
                color: var(--font-color-error);

                &:hover span{
                  color: var(--font-color-error);
                  border-color: var(--font-color-error);
                }
              }
            }
          }
        }

        .hamburger {
          margin-right: 3px;
          z-index: 999;

          img {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}

.headerNotificationsButton {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 720px) {
  .notificationsBlock {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .headerNotificationsButton {
    display: none;
  }
}

.mobileAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popupWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
}

.details {
  font-size: 14px;
  color: rgb(156, 161, 175);
}

.separator {
  width: 100%;
   height: 1px;
   margin-top: 6px;
   background: var(--layout-color-steel)
}

.exitButton {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  outline: none;
  border: none;
  padding: 6px 0;
  font-size: 14px;
  color: var(--font-color-error);
  cursor: pointer;
}

.email {
      width: 168px;
      text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
      font-size: 14px;
      color: rgb(156, 161, 175);
}

.detailsWrapper {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}