@use '../../../assets/styles/responsive.scss' as resp;

.tile {
  max-width: 262px;
  border: 1px solid transparent;
  padding: var(--layout-padding-reduced);
  border-radius: var(--layout-border-radius-base);
  color: var(--font-color-base);
  text-decoration: unset;
  overflow-wrap: anywhere; // Comp: 95.23%. The long, glued text should be broken up
  box-sizing: border-box;

  &:hover {
    color: var(--font-color-base);
    background-color: var(--hover-color);
  }

  @include resp.responsive(phone) {
    width: 100%;
  }

  .image {
    border-radius: var(--layout-border-radius-base);
    overflow: hidden;

    @include resp.responsive(desktop) {
      min-width: 214px;
      min-height: 120px;
      max-width: 230px;
      max-height: 130px;
    }

    @include resp.responsive(phone) {
      aspect-ratio: 16 / 9;
    }

    img {
      max-width: 100%;
      width: auto;
      height:auto;
    }
  }

  .description {
    padding: 0 0 5px 0;
  }

  .title,
  .content {
    margin: 0 0.2em;
  }

  .title {
    padding: 1em 0 0;
    font-size: 1em;
    line-height: 1.4em;
    font-weight: 600;
  }

  .content {
    padding: 10px 0 0;
    font-size: 0.8em;
    line-height: 1.4em;
    color: var(--font-color-base);
    -webkit-line-clamp: 2;
  }
}

@media screen and (max-width: 1023px) {
  .tile {
    &:hover {
      background-color: transparent;
    }
    .content {
      color: #6E6E73;
    }
  }
}