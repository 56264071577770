$themeDark: '.themeDark';

.themeDark {
  --page-background-color: #161c1d;
  --banner-background-color: #161c1d;

  --font-color-base: #f0f0f0;
  --font-color-muted: #cecece;
  --font-color-error: #e50000;
  --font-color-link: #5c9dff;
  --font-color-link-hover: #7db1ff;

  --font-color-banner-text: #9f9f9f;

  --layout-background-color: #222c2c;
  --layout-background-emphasize-color: #1d2727;
  --layout-background-modal-color: #222c2c;
  --layout-shadows-color-rgb: 0, 0, 0;

  --layout-border-color: #40494a;
  --layout-border-color-light: #292c2c;
  --layout-border-color-dark: #4f5b5c;

  --layout-secondary-color-base: #014833;
  --layout-secondary-color-light: #013b2a;
  --layout-secondary-color-dark: #036246;

  // Scroll
  --scrollbar-track-base-color: #353c3d;
  --scrollbar-track-active-color: #40494a;
  --scrollbar-thumb-base-color: #086331;
  --scrollbar-thumb-active-color: #00833b;

  // Player
  --video-control-background-color: #222c2c;
  --video-timeline-background-color: #3f484b;
  --video-timeline-loaded-color: #4d5a50;
  --video-timeline-played-color: #00833b;
  --video-timeline-target-color: #00833b;
  --video-play-btn-background-color: #161c1d;
  --video-play-btn-border-color: #161c1d;
  --video-play-btn-hover-background-color: black;
  --video-play-btn-hover-border-color: black;

  // Other
  --loader-color: #00833b;

  --trainer-section-background: #202022;
  --stream-shedule-background: #222c2c;
  --trainer-page-mobile-background: #222c2c;

  // Buttons
  --button-base-backgound-color: #efefef;
  --button-base-backgound-color-hover: #b5b5b5;
  --button-base-text-color-base: black;
  --button-base-text-color-hover: black;

  --button-base-background: #013425;
  --button-colorful-backgound-color: var(--layout-secondary-color-base);
  --button-colorful-backgound-dark: var(--layout-secondary-color-dark);
  --button-colorful-text-color-base: white;
  --button-colorful-text-color-hover: white;

  // Skeleton
  --skeleton-base-color: #40494a;
  --skeleton-highlight-color: #5f6c6d;
  --skeleton-image-color: #cfe7e1;

  // Hover color
  --hover-color: #00000030;

  --stroke: #d6d6d6;
}
