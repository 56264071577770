.dropdown {
  min-width: 200px;
  border: 1px solid var(--layout-border-color);
  border-radius: var(--layout-border-radius-base);
  padding: var(--layout-padding-reduced);
  background-color: var(--layout-background-modal-color);
  box-shadow: 0 0 10px 0 rgba(var(--layout-shadows-color-rgb), 0.2);
  & .title {
    padding-bottom: 0.5em;
    font-size: 1.3em;
    font-weight: 600;
  }
}