@use './assets/styles/variables.scss' as var;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  padding: 0;
  margin: 0;
  color: var(--font-color-base);
  font-size: var(--font-size-base);
  font-family: var(--font-family-base);
  font-variant-numeric: lining-nums;
}
html,
body,
#root {
  height: 100%;
}
a {
  color: var(--font-color-link);
}
a:hover {
  color: var(--font-color-link-hover);
}
strong {
  font-weight: var(--font-weight-bold);
}
h1 {
  margin: 0.5em 0;
  font-size: 2em;
  font-weight: var(--font-weight-normal);
}
h2 {
  margin: 0.5em 0;
  font-size: 1.8em;
  font-weight: var(--font-weight-normal);
}
h3 {
  margin: 0.5em 0;
  font-size: 1.3em;
  font-weight: var(--font-weight-normal);
}
img {
  display: block;
}
