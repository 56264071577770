@use '../../../assets/styles/responsive.scss' as resp;

.menuitem {
  flex: 1 1;
  padding: 0.1em 0.5em;
  font-size: 1.2em;
  color: var(--font-color-base);
  white-space: nowrap;
  text-align: center;
  text-decoration: unset;
  cursor: pointer;

  @include resp.responsive(phone_tablet) {
    white-space: unset;
  }

  span {
    display: inline-block;
    padding: 0.2em 0.5em;
    border-bottom: var(--menu-underline-size) solid transparent;
  }

  &:hover {
    span {
      color: var(--font-color-base);
      border-bottom: var(--menu-underline-size) solid var(--font-color-base);
    }
  }

}

.active {

  span {
    border-bottom: var(--menu-underline-size) solid var(--font-color-muted);
  }
}