@use '../../assets/styles/responsive.scss' as resp;

.holder {
  display: block;
}
.title {
  text-align: center;
  margin: 1em 0.5em 1.25em;
  font-weight: var(--font-weight-bold);
}
.block {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: var(--layout-padding-base);
  border: 1px solid var(--layout-border-color);
  border-radius: var(--layout-border-radius-base);
  box-shadow: 0 0 3px 0 var(--layout-border-color);
  box-sizing: border-box;
  background-color: var(--layout-background-color);
}
.form {
  @include resp.responsive(phone) {
    border: unset;
    box-shadow: unset;
  }
}
.actionBlock {
  opacity: 0.5;
  cursor: default;
  margin-top: 0.75em;

  a {
    display: block;
    text-decoration: none;
    font-size: 1em;
    line-height: 2em;
  }
}
