@use '../../assets/styles/mixins.scss' as mixin;
@use '../../assets/styles/responsive.scss' as resp;

.wrapper {
  display: flex;
  flex-direction: column;

  .card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 80px;
    padding: var(--layout-padding-thin) var(--layout-padding-base);
    justify-content: center;
    border-bottom: 1px solid var(--layout-border-color);
    // background-color: var(--layout-background-emphasize-color);

    &:last-of-type {
      border: 0;

      @include resp.responsive(desktop) {
        @include mixin.smooth-bottom-border;
      }
     }

    &:hover {
      background-color: var(--layout-secondary-color-light);
      // background-color: var(--layout-);
      cursor: pointer;
    }

    .titleHolder {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.3em;

      .title {
        flex: 1 1;
        font-size: 0.9em;
        font-weight: 600;
      }

      .time {
        flex: 0 0;
        padding: 0.5em 1em;
        border-radius: 1em;
        background-color: var(--layout-background-emphasize-color);
        text-align: center;
        font-family: var(--font-family-numeric);
        font-size: 0.7em;
        font-weight: var(--font-weight-bold);
        color: var(--font-color-link);
      }
    }

    .text {
      font-size: 0.9em;
      color: var(--font-color-muted);

    }
  }
}
.text {
  display: flex;
  flex-direction: column;
  gap: var(--layout-padding-base);
}