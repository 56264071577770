.card {
  padding-bottom: 0.75em;

  .personal {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;

    .photo {
      flex: 0 0 56px;
      min-height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        border-radius: 50%;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .info {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: .5rem;
      margin-left: 14px;
      font-family: var(--font-family-base);
      overflow-wrap: anywhere;

      .name {
        font-weight: 600;
        font-size: 1.25em;
      }

      .phone,
      .email {
        // font-weight: 600;
        font-size: 0.875em;
        color: var(--font-color-muted);
      }
      .phone {
        .plus {
          font-size: 1.3em;
          padding-right: 0.03em;
        }
      }
    }
  }

  .stats {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 0.8em 0;
    font-size: 15px;

    .duration {
      flex: 1 1;
    }

    .completed {
      flex: 1 1;
    }
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--stroke);
    text-decoration: none;
    line-height: 1em;
    border: 1px solid var(--stroke);
    border-radius: 12px;
    padding: 10px 0px;
    transition: all 0.3s ease;
    margin: 0.5em 0 0.3em;
    font-size: 15px;

    &:hover {
      opacity: 0.8;
    }
  }
}
