@use '../../../assets/styles/themes.scss' as themes;

.controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }

  .playBtn,
  .timeSkip5F,
  .timeSkip5B,
  .timeSkip10F,
  .timeSkip10B {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--video-timeline-background-color);
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-user-select: none;
    user-select: none;
    box-shadow: 0 -0 32px 0 rgba(0, 0, 0, 0.35);
    opacity: 0;

    @keyframes blink1 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes blink2 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes blink3 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes blink4 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes blink5 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    @keyframes blink6 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  .playBtn {
    &.play {
      // background-image: url('../../../assets/icons/themes/light/play.svg');
      animation-name: blink1;
      animation-duration: 1s;

      @include themes.with(themes.$themeLight) {
        background-image: url('../../../assets/icons/themes/light/play.svg');
      }
      @include themes.with(themes.$themeDark) {
        background-image: url('../../../assets/icons/themes/dark/play.svg');
      }
    }

    &.pause {
      // background-image: url('../../../assets/icons/themes/light/pause.svg');
      animation-name: blink2;
      animation-duration: 1s;

      @include themes.with(themes.$themeLight) {
        background-image: url('../../../assets/icons/themes/light/pause.svg');
      }
      @include themes.with(themes.$themeDark) {
        background-image: url('../../../assets/icons/themes/dark/pause.svg');
      }
    }
  }

  .timeSkip5F {
    &.skip {
      // background-image: url('../../../assets/icons/themes/light/play.svg');
      animation-name: blink3;
      animation-duration: 0.5s;

      @include themes.with(themes.$themeLight) {
        background-image: url('../../../assets/icons/themes/light/skip5SF.svg');
      }
      @include themes.with(themes.$themeDark) {
        background-image: url('../../../assets/icons/themes/dark/skip5SF.svg');
      }
    }
  }

  .timeSkip5B {
    &.skip {
      // background-image: url('../../../assets/icons/themes/light/play.svg');
      animation-name: blink4;
      animation-duration: 0.5s;

      @include themes.with(themes.$themeLight) {
        background-image: url('../../../assets/icons/themes/light/skip5SB.svg');
      }
      @include themes.with(themes.$themeDark) {
        background-image: url('../../../assets/icons/themes/dark/skip5SB.svg');
      }
    }
  }

  .timeSkip10F {
    &.skip {
      // background-image: url('../../../assets/icons/themes/light/play.svg');
      animation-name: blink5;
      animation-duration: 0.5s;

      @include themes.with(themes.$themeLight) {
        background-image: url('../../../assets/icons/themes/light/skip5SF.svg');
      }
      @include themes.with(themes.$themeDark) {
        background-image: url('../../../assets/icons/themes/dark/skip5SF.svg');
      }
    }
  }

  .timeSkip10B {
    &.skip {
      // background-image: url('../../../assets/icons/themes/light/play.svg');
      animation-name: blink6;
      animation-duration: 0.5s;

      @include themes.with(themes.$themeLight) {
        background-image: url('../../../assets/icons/themes/light/skip5SB.svg');
      }
      @include themes.with(themes.$themeDark) {
        background-image: url('../../../assets/icons/themes/dark/skip5SB.svg');
      }
    }
  }

  .videoLineContainer {
    position: absolute;
    display: flex;
    bottom: 12px;
    left: 12px;
    right: 12px;
    height: 32px;
    padding: 0 12px 0 5px;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    background-color: var(--video-control-background-color);
    border-radius: 16px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

    .smallPlayBtn {
      flex: 0 0 22px;
      width: 22px;
      height: 22px;
      background-color: var(--video-play-btn-background-color);
      background-size: 16px 16px;
      background-position: 50%;
      background-repeat: no-repeat;
      border: 1px solid var(--video-play-btn-border-color);
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: var(--video-play-btn-hover-background-color);
        border-color: var(--video-play-btn-hover-border-color);
      }

      &.play {
        background-image: url('../../../assets/icons/themes/light/play.svg');

        @include themes.with(themes.$themeLight) {
          background-image: url('../../../assets/icons/themes/light/play.svg');
        }
        @include themes.with(themes.$themeDark) {
          background-image: url('../../../assets/icons/themes/dark/play.svg');
        }
      }
      &.pause {
        background-image: url('../../../assets/icons/themes/light/pause.svg');

        @include themes.with(themes.$themeLight) {
          background-image: url('../../../assets/icons/themes/light/pause.svg');
        }
        @include themes.with(themes.$themeDark) {
          background-image: url('../../../assets/icons/themes/dark/pause.svg');
        }
      }
    }

    .time {
      padding: 0 10px;
      font-family: var(--font-family-numeric);
      font-size: 0.75em;
      font-weight: var(--font-weight-normal);
      white-space: nowrap;
      -webkit-user-select: text;
      user-select: text;

      .current {
        display: inline-block;
        color: var(--font-color-base);
        text-align: right;

        &.width1 {
          min-width: 24px;
        }
        &.width2 {
          min-width: 30px;
        }
        &.width3 {
          min-width: 40px;
        }
      }
      .total {
        color: var(--font-color-muted);
      }
      .delimiter {
        padding: 3px;

        &::after {
          content: '/';
        }
      }
    }

    .videoLine {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;

      & > input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
      }
      & > input::-webkit-slider-runnable-track {
        height: 18px;
        background-color: var(--video-timeline-background-color);
        border-radius: 9px;
      }
      & > input::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #eaa7eb;
        height: 18px;
        width: 18px;
        border-radius: 50%;
      }
    }

    .buttons {
      flex: 0 0 90px;
      display: flex;
      flex-direction: row;
      width: 80px;
      height: 100%;
      margin-left: var(--layout-padding-thin);

      .btnContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1;
        height: 100%;
        cursor: pointer;

        img {
          height: 18px;
          margin: 0 auto;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
      }

      .settingsBtn {
        img {
          transition: transform 0.3s;
        }
        &.active img {
          transform: rotate(30deg);
        }

        .configMenuWrapper {
          position: absolute;
          bottom: calc(var(--layout-padding-base) * 1.75);
          right: calc(0px - var(--layout-padding-thin));
          padding: 12px calc(var(--layout-padding-thin));
          -webkit-user-select: none;
          user-select: none;

          .configMenu {
            width: 200px;
            padding: var(--layout-padding-thin) 0;
            background-color: var(--layout-background-modal-color);
            border-radius: var(--layout-border-radius-reduced);
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            overflow: hidden;

            .configLine {
              display: grid;
              grid-template-columns: 1fr 0.5fr;
              padding: var(--layout-padding-very-thin)
                var(--layout-padding-base);

              &:hover {
                background-color: var(--layout-secondary-color-light);
              }
              .configValue {
                text-align: right;
              }
            }
          }

          .configSubmenu {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0;

            .holder {
              height: 0;
              max-height: 0;
              transition: max-height 1s;
              margin: var(--layout-padding-reduced) var(--layout-padding-thin);
              background-color: var(--layout-background-modal-color);
              border-radius: var(--layout-border-radius-reduced);
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
              overflow: hidden;
            }

            &.active {
              height: auto;

              .holder {
                height: 100%;
                min-height: 100%;
                max-height: 500px;

                .configLine {
                  padding: var(--layout-padding-very-thin)
                    var(--layout-padding-thick);
                  text-align: right;

                  &:hover {
                    background-color: var(--layout-secondary-color-light);
                  }

                  &:first-of-type {
                    padding-top: var(--layout-padding-thin);
                    background-color: unset;
                    border-bottom: 1px solid var(--layout-border-color);
                  }
                  &:last-of-type {
                    padding-bottom: var(--layout-padding-thin);
                  }
                  &.active {
                    background-color: var(--layout-secondary-color-base);
                  }
                }
              }
            }
          }
        }
      }

      .volumeBtn {
        position: relative;

        &.volumeMuted {
          filter: saturate(0);
        }

        &:hover {
          .volumeMenuWrapper {
            display: block;
          }
        }
        &.volumeMuted,
        &.volumeMuted:hover {
          .volumeMenuWrapper {
            display: none;
          }
        }

        .volumeMenuWrapper {
          display: none;
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: transparent;
          padding: 12px 8px;
          transform: rotate(270deg) translate(50%, 50px);

          .volumeMenu {
            width: 100px;
            padding: 0 10px;
            border-radius: 16px;
            background-color: #fff;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

            & > input {
              display: block;
              width: 100px;
              height: 32px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
