@mixin custom-scroll{

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: var(--scrollbar-track-base-color);
  }
  &::-webkit-scrollbar-track:hover {
    background-color: var(--scrollbar-track-active-color);
  }
  &::-webkit-scrollbar-track:active {
    background-color: var(--scrollbar-track-active-color);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--scrollbar-thumb-base-color);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-active-color);
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-thumb-active-color);
  }
}

@mixin custom-scroll-padded{

  &::-webkit-scrollbar{
    width: 12px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active{
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb{
    border: solid 3px transparent;
    border-radius:  8px;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb::after{
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
  }
}

@mixin smooth-bottom-border {
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, var(--layout-background-color) 0%, var(--layout-border-color) 20%, var(--layout-border-color) 80%, var(--layout-background-color) 100%);
  }
}

@keyframes loader-animation {to{transform: rotate(1turn)}}

@mixin loader {
  &::after {
    content: ' ';
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, var(--loader-color));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: loader-animation 1s infinite linear;
  }
}