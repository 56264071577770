@use '../../assets/styles/responsive.scss' as resp;

.blankLayout {
  flex: 1 1;
  flex-direction: column;
  display: flex;
  max-width: var(--layout-max-width);
  min-height: 100%;
  margin: 0 auto;

  @include resp.responsive(phone) {
    background-color: var(--layout-background-color);
  }
}