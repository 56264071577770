@import './themes/light.scss';
@import './themes/dark.scss';

@mixin with($theme) {
  @if ($theme == $themeDark) {
    :global(.themeDark) & {
      @content;
    }
  }
  @if ($theme == $themeLight) {
    :global(.themeLight) & {
      @content;
    }
  }
}